import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import 'jquery-slimscroll';

import {getTrHeight} from "./datatables";



export const mouseWheelScroll = () =>{


    $(".dataTables_scrollBody").mCustomScrollbar({
        axis: 'y',
        theme: 'minimal',
        scrollbarPosition: 'outside',
        autoHideScrollbar: true,
        alwaysShowScrollbar: 0,
        snapAmount: getTrHeight(),
        mouseWheel: {
            enabled: true,
            deltaFactor: 0.5,
            normalizeDelta: 5,
            preventDefault: true,
            axis: "y",
            scrollAmount: getTrHeight()
        },
        contentTouchScroll: 5,
        mouseWheelPixels: getTrHeight(),
        scrollInertia:15,
        scrollButtons: {
            sa: getTrHeight(),
            scrollType: "pixels"
        },
        callbacks:{
            onScroll:function(el){
                console.log("is-scrolling");
            }
        }
    });

    window.addEventListener('resize', function (){
        $(".dataTables_scrollBody").mCustomScrollbar('destroy');
        $(".dataTables_scrollBody").mCustomScrollbar({
            axis: 'y',
            theme: 'minimal',
            scrollbarPosition: 'outside',
            autoHideScrollbar: true,
            alwaysShowScrollbar: 0,
            snapAmount: getTrHeight(),
            mouseWheel: {
                enabled: true,
                deltaFactor: 0.5,
                normalizeDelta: 5,
                preventDefault: true,
                axis: "y",
                scrollAmount: getTrHeight()
            },
            contentTouchScroll: 5,
            mouseWheelPixels: getTrHeight(),
            scrollInertia:15,
            scrollButtons: {
                sa: getTrHeight(),
                scrollType: "pixels"
            },
            callbacks:{
                onScroll:function(el){
                    console.log("is-scrolling");
                }
            }
        });
    });
}



