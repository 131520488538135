import $ from 'jquery';
import 'datatables.net';

import {mouseWheelScroll} from "./Mousewheel";
import {isMobile} from "./website";
import {scrollTableDown, scrollTableUp} from "./menufilters";
import global from "./global";
var imagesLoaded = require('imagesloaded');

var table;


export const dataTable = (saveState) => {

    global.windowWidth = window.innerWidth;

    if(document.body.getAttribute("id") === "projects"){


        table = $('#projects-table').DataTable({
            scrollResize: true,
            paging: false,
            searching: false,
            info: false,
            destroy: true,
            scrollY: "50vh - 3em",
            stateSave: saveState,
            /* scrollCollapse: true,*/
            responsive: true,
            initComplete: function () {
                const ScrollContainer = document.querySelector('.dataTables_scrollBody');
                showThumbsAndTileOnHover();
                mouseWheelScroll();
                SetTableMaxHeight();
                if (saveState === true) {
                    const filters = document.querySelectorAll(".filters p");
                    const tableElHeader = document.querySelector("#projects-table_wrapper table");
                    const tableEl = document.getElementById("projects-table");
                    filters.forEach((filter) => {
                        if (filter.dataset.target == "all") {
                            filter.classList.add("active");
                            tableElHeader.classList.remove("selection");
                            tableEl.classList.add("filtered");
                        } else {
                            filter.classList.remove("active");
                            tableEl.classList.remove("filtered");
                        }
                    });
                    if (window.innerWidth < 768) {
                        const pageTitle = document.querySelector('.page-title');
                        pageTitle.style.display = "none";
                        pageTitle.classList.add("fade-out-delay");
                    }
                    scrollTableUp(0);
                }
                TableHasBeenFiltered();
            }
        });

        /* if (saveState == false) {
             console.log("datatable" + saveState);
             table.order([0, 'asc']).draw();
         }*/

        filters(table);
    }

    /*const columnsTitleEl = document.querySelectorAll('thead th');
    var clicked = 0;*/

    /*columnsTitleEl.forEach((el) => {
        el.addEventListener("click", () => {
            clicked += 1;
            if ((clicked == 3) || !el.classList.contains('sorting_desc') && !el.classList.contains('sorting_asc')) {
                table.order([0, 'asc']).draw();
                clicked = 0;
            }
        });
    });*/
}

window.addEventListener("load", (event) => {
    window.dispatchEvent(new Event('resize'));

});


window.addEventListener("resize", () => {
    if(document.body.getAttribute("id") === "projects") {
        const body = document.body;
        const pageTitle = document.querySelector('.page-title');
        var windowWidth = window.innerWidth;
        body.classList.add('no-animation');

        table.destroy();
        dataTable();

        if (document.getElementById("#projects") !== 'undefined') {
            SetTableMaxHeight();
            if (window.innerWidth <= 768) {
                pageTitle.classList.remove('fade-out-delay');
                pageTitle.classList.add('fade-in-delay');
            } else {

            }
            const Activefilter = document.querySelectorAll(".filters p.active")[0];
            if (Activefilter.dataset.target == "selected") {
                scrollTableDown(10);
            } else {
                if (window.innerWidth < 768) {
                    pageTitle.classList.add('fade-out-delay');
                } else {
                    pageTitle.classList.remove('fade-out-delay');
                }
                scrollTableUp(0);
            }

        }
    }

});

const filters = (table) => {

    const filters = document.querySelectorAll(".filters p");
    const tableRows = document.querySelectorAll("table tbody tr");
    const tablescrollBody = document.querySelector(".dataTables_scrollBody");
    const tableElHeader = document.querySelector('#projects-table_wrapper table');
    const tableEl = document.getElementById("projects-table");
    const pageTitle = document.querySelector('.page-title');

    filters.forEach((filter) => {
        filter.addEventListener("click", () => {
            table.order([0, 'asc']).draw();
            tableEl.classList.remove("filtered");

            filters.forEach((filter) => {
                filter.classList.remove('active');
            });

            filter.classList.add('active');
            if (filter.dataset.target === "all") {
                global.activefilter = "all";
                tableElHeader.classList.remove('selection');
                scrollTableUp(100);
                if (window.innerWidth <= 768) {
                    pageTitle.classList.add('fade-out-delay');
                }
                /*tablescrollBody.classList.add("no-margin");*/
                /*tableRows.forEach((tableRow) =>{
                    tableRow.classList.add('show');
                });*/
            } else if (filter.dataset.target === "selected") {
                global.activefilter = null;
                console.log(filter.dataset.target);
                tableElHeader.classList.add('selection');
                scrollTableDown(600);
                setTimeout(function () {
                    if (window.innerWidth <= 768) {
                        pageTitle.style.display = null;
                        pageTitle.classList.remove('fade-out-delay');
                    }
                }, 500);

                tablescrollBody.classList.remove("no-margin");
                tableRows.forEach((tableRow) => {
                    if (tableRow.classList.contains("selected")) {
                        tableRow.classList.add('show');
                    } else {
                        tableRow.classList.remove('show');
                        tableRow.classList.add('not-selected');
                    }
                });
            }
        });

    });
}

const showThumbsAndTileOnHover = () => {

    if (isMobile.any()) {

    } else{

        const tableRows = document.querySelectorAll('#projects-table tbody tr');
        const thumbsContainer = document.querySelector('.thumbs-container');
        const pageTitleEl = document.querySelector(".page-title");
        const pageTitle = pageTitleEl.innerHTML;



        tableRows.forEach((row) => {

            row.addEventListener('mouseenter', () => {
                if(global.windowWidth > 768) {
                    const thumbs = row.dataset.thumbs;
                    thumbsContainer.innerHTML = thumbs;
                    const title = row.querySelector(".name span").innerHTML;
                    pageTitleEl.innerHTML = title;
                    imagesLoaded('.thumbs-container', function () {
                        thumbsContainer.classList.add('show');
                    });
                }

            });

            row.addEventListener('mouseleave', () => {
                if(global.windowWidth > 768) {
                    thumbsContainer.innerHTML = null;
                    pageTitleEl.innerHTML = pageTitle;
                    thumbsContainer.classList.remove('show');
                }
            });

        });
    }
}

const SetTableMaxHeight = () => {
    const tableScrollBody = document.querySelector('.dataTables_scrollBody');
    const trData = document.querySelector('#projects-table tbody tr:first-of-type').getBoundingClientRect();
    var trHeight = trData.height;
    var wH = window.innerHeight;
    var fontSize = getComputedStyle(document.body).getPropertyValue('font-size');
    fontSize = parseFloat(fontSize);
    var margin = (fontSize * 1.8) + fontSize + 5;

    var tableMaxHeight = ((wH / 2) - margin) / getTrHeight();
    tableMaxHeight = (Math.round(tableMaxHeight)) * getTrHeight();
    tableScrollBody.style.maxHeight = tableMaxHeight + "px";

}

const TableHasBeenFiltered = () => {
    const sortingTh = document.querySelectorAll(".sorting");
    const tableEl = document.getElementById("projects-table");

    sortingTh.forEach((item) => {
        item.addEventListener("click", () => {
            global.filtered = true;
            tableEl.classList.add("filtered");
        });
    });

}

const resetDataTable = (table) => {
    table.order([0, 'asc']).draw();
}

export const getTrHeight = () => {
    const trData = document.querySelectorAll('.selected')[0].getBoundingClientRect();
    const trHeight = trData.height;
    return trHeight;
}
