__webpack_public_path__ = "http://dev.local:8080/assets/";



import "../scss/application.scss";
import global from "./_modules/global";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


import './_modules/ajax';





document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();
});