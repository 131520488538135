import Swiper, {
    Autoplay,
    EffectFade,
    FreeMode,
    HashNavigation,
    Keyboard,
    Lazy,
    Manipulation,
    Mousewheel,
    Navigation
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';


export const Carousel = () => {

    const slider = document.querySelector(".swiper-container");
    if (slider) {
        const swiperH = new Swiper('.swiper-container', {
            modules: [Navigation, Lazy, Manipulation, EffectFade, Mousewheel, Autoplay, Keyboard],
            cssMode: false,
            direction: 'horizontal',
            followFinger: true,
            preloadImages: false,
            resizeObserver: false,
            allowTouchMove: true,
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            loop: true,
            lazy: {
                checkInView: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: false,
            },
            autoplay: false,
            watchSlidesProgress: true,
            speed: 300,
            on: {
                init: function () {
                },
                lazyImageReady: function () {
                    console.log('load');
                    if (this.el.classList.contains("hide")) {
                        this.el.classList.remove("hide");
                    }
                },
                activeIndexChange: function (){
                    const paginationEl = document.querySelector('.pagination .active-index')
                    paginationEl.innerHTML = this.realIndex + 1;
                }

            }
        });
    }
}
