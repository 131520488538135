export const scrollFade = () => {
    const scrollContainer = document.querySelector("main");
    const fadeInScrollText = document.querySelectorAll("#home .fade-in-scroll");
    const HiddenEls = document.querySelectorAll(".hide");
    const currentSection = document.querySelector('.current-section');
    const infoContainer = document.querySelector('.informations-container');
    const xsFooter = document.querySelector('.xs-footer');
    const title = document.querySelector('.content-container .trigger-scroll');
    const body = document.body;

    currentSection.innerHTML = "";

    const infoSections = document.querySelectorAll('.info-section');

    console.log('scrollFade');

    title?.addEventListener("click", () =>{
        infoContainer.classList.remove("hide");
        xsFooter.classList.remove('hide');
        body.classList.remove('no-animation');
        var top = title.getBoundingClientRect().top;
        if(window.innerWidth < 768){
            top = title.getBoundingClientRect().top - 5*16;
        }
        scrollContainer.scroll({top: top, left: 0, behavior: 'smooth'});
        title.classList.remove("trigger-scroll");
    }, {once : true});

    scrollContainer.addEventListener("scroll", () => {

        body.classList.remove('no-animation');

        if (scrollContainer.scrollTop > 50) {
            fadeInScrollText.forEach((fadeInScrollItem) => {
                fadeInScrollItem.classList.add("fade-in");
            });
            HiddenEls.forEach((HiddenEl) =>{
                HiddenEl.classList.remove('hide');
            })
        }else{
            fadeInScrollText.forEach((fadeInScrollItem) => {
                fadeInScrollItem.classList.remove("fade-in");
            });
            HiddenEls.forEach((HiddenEl) =>{
                HiddenEl.classList.add('hide');
            })
        }

        infoSections.forEach((infoSection) =>{

            const title = infoSection.querySelector(".info-title").innerHTML;
            const offsetTop = infoSection.offsetTop;
            console.log(title);

            if (scrollContainer.scrollTop > offsetTop && offsetTop !== 0){
                console.log(title+"in-viewport");
                currentSection.innerHTML = title;
                console.log(offsetTop);
                console.log(scrollContainer.scrollTop);
            }

        });

    });

}

