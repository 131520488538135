import {getActiveFilter, menuFilters} from "./menufilters";

const loadLinks = document.querySelectorAll(".load-page");
const ajaxContainer = document.querySelector('main');
const body = document.body;

import global from "./global";
import {dataTable} from "./datatables";
import {resetDataTable} from "./datatables";
import {filters} from "./menufilters";
import {scrollFade} from "./scrollFade";
import {Carousel} from "./swiper";
import {scrollTableUp} from "./menufilters";

var saveState = false;

const Init = () => {
    dataTable(saveState);
    scrollFade();
    Carousel();
    if(document.body.getAttribute("id") == "projects"){
        global.filtered = getActiveFilter();
    }



}

Init();

body.addEventListener("click", (e)=>{
    const { target } = e;

    if (target.matches('.load-page')) {


        var targetPageId = target.dataset.page
        var activePage = document.body.getAttribute("id");

        if (activePage === "project" &&  global.filtered !== false){
            saveState = true;
        }else if(activePage === "project" && global.activefilter === "all"){
            saveState = true;
        }else{
            saveState = false;
        }


            e.preventDefault();
            loadLinks.forEach((link) =>{
                link.classList.remove('active');
            });

            target.classList.add('active');

            ajaxContainer.classList.add('fade-out');
            const url = target.dataset.page;
            var pageId =  url.replace('/', "");
            var targetUrl;
            if (url === "/"){
                targetUrl = "home.json";
                pageId = "a-propos";
            }else{
                targetUrl = url + ".json"
            }
            fetch(targetUrl)
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    const html = data['html'];
                    const template = data['template'];
                    ajaxContainer.innerHTML = null;
                    ajaxContainer.scrollTop = 0;

                    body.setAttribute("id", template);
                    history.pushState( null,'Title of the page', url);
                    ajaxContainer.innerHTML = html;

                    Init();
                    console.log('init');
                    ajaxContainer.classList.remove('fade-out');

                    const activeMenuLink = document.querySelector("nav a.load-page[data-page='"+targetPageId+"']");
                    if(activeMenuLink !== null){
                        activeMenuLink.classList.add('active');
                    }

                    setTimeout(function (){
                        if(document.body.getAttribute("id") === "projects") {
                            window.dispatchEvent(new Event('resize'));
                        }
                    },0);

                });
        }
});






