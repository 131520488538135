export const filters = () => {

    const filters = document.querySelectorAll(".filters p");
    const tableRows = document.querySelectorAll("table tbody tr");
    const tablescrollBody = document.querySelector(".dataTables_scrollBody");
    const tableEl = document.querySelector('#projects-table_wrapper table');

    filters.forEach((filter) => {
        filter.addEventListener("click", () => {
            getActiveFilter();
            filters.forEach((filter) =>{
                filter.classList.remove('active');
            });
            filter.classList.add('active');
            if(filter.dataset.target === "all"){
                tableEl.classList.remove('selection');
                scrollTableUp();
                /*tablescrollBody.classList.add("no-margin");*/
                /*tableRows.forEach((tableRow) =>{
                    tableRow.classList.add('show');
                });*/
            }else if (filter.dataset.target === "selected"){
                console.log(filter.dataset.target);
                tableEl.classList.add('selection');
                scrollTableDown();
                tablescrollBody.classList.remove("no-margin");
                tableRows.forEach((tableRow) =>{
                    if(tableRow.classList.contains("selected")){
                        tableRow.classList.add('show');
                    }else{
                        tableRow.classList.remove('show');
                        tableRow.classList.add('not-selected');
                    }
                });
            }
        });

    });

}

export const getActiveFilter = () => {

    const filter = document.querySelector(".filters p.active");

    if(filter === "all"){
       return true;

    }else if (filter === "selected"){
       return false;
    }else{
        return false;
    }
}

export const scrollTableUp = (delay) =>{
    const table = document.getElementById('projects-table_wrapper');
    const tableRows = document.querySelectorAll("table tbody tr:not(.selected)");
    const tableTag = document.querySelector('#projects-table_wrapper table');
    const body = document.body;

    var offsetTop = table.offsetTop;
    table.style.top = offsetTop + "px";

    setTimeout(function (){
        table.classList.add('up');
        body.classList.remove('no-animation');
    },delay);

    if(delay === 0){
        fadeInRows(0);
    }else{
        fadeInRows(50);
    }
    tableTag.classList.remove("selection");
}

export const scrollTableDown = (delay) =>{
    const table = document.getElementById('projects-table_wrapper');
    const parentEl = document.getElementById('projects-table_wrapper').parentElement;
    const tableRows = document.querySelectorAll("table tbody tr");
    const tableTag = document.querySelector('#projects-table_wrapper table');
    var top = parentEl.offsetHeight - table.offsetHeight;
    const body = document.body;


    const tableRowsNotSelected = document.querySelectorAll("table tbody tr:not(.selected)");
    tableRows.forEach((tableRow) =>{
        tableRow.classList.remove('fade-in');
    });



    setTimeout(function (){
        top = parentEl.offsetHeight - table.offsetHeight;
        table.style.top = top + "px";
        table.classList.remove('up');
        body.classList.remove('no-animation');
    },delay);


    tableRows.forEach((tableRow) =>{
        tableRow.classList.remove('show');
        tableRow.classList.remove('fade-in');
    });
    tableTag.classList.add("selection");
}

const fadeInRows = (delay) =>{
    const table = document.getElementById('projects-table_wrapper');
    const tableRows = document.querySelectorAll("table tbody tr:not(.selected)");

    tableRows.forEach((tableRow) =>{
        tableRow.classList.add('show');
        var d = 0;
        tableRows.forEach((tableRow) =>{
            setTimeout(function (){
                if(d == 0){
                    tableRow.classList.add('no-transition');
                    tableRow.classList.add('fade-in');
                }else{
                    tableRow.classList.add('fade-in');
                }

            },d)
            setTimeout(function (){
                tableRow.classList.remove('no-transition');
            },100);
            d +=delay;

        });
    });

}


